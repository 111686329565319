<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <el-button type="primary" @click="showArticleCategoryDialog(null)">添加</el-button>
                </div>
            </div>
            <el-table :data="articleCategories" class="mt-2">
                <el-table-column prop="name" label="名称" sortable />
                <el-table-column prop="sort" label="排序" sortable width="100" />
                <el-table-column label="操作" fixed="right" width="100">
                    <template #default="scope">
                        <el-button type="text" @click="showArticleCategoryDialog(scope.row)">修改</el-button>
                        <el-button type="text" class="text-danger" @click="deleteArticleCategory(scope.row.id)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <el-dialog :visible.sync="articleCategoryDialogVisible" title="通知分类" :close-on-click-modal="false"
            width="400px">
            <el-form>
                <el-form-item label="名称">
                    <el-input v-model="articleCategory.name" placeholder="名称" />
                </el-form-item>
                <el-form-item label="排序">
                    <el-input-number v-model="articleCategory.sort" controls-position="right" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveArticleCategory">确定</el-button>
                <el-button @click="articleCategoryDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                articleCategories: [],
                articleCategory: {},
                articleCategoryDialogVisible: false,
            };
        },
        methods: {
            async loadArticleCategories() {
                let response = await this.$axios.get('/api/ArticleCategory/GetArticleCategories');
                this.articleCategories = response.data;
            },
            showArticleCategoryDialog(articleCategory) {
                if (articleCategory) {
                    this.articleCategory = JSON.parse(JSON.stringify(articleCategory));
                }
                else {
                    this.articleCategory = { sort: this.getNextNumber(this.articleCategories.map(p => p.sort)) };
                }
                this.articleCategoryDialogVisible = true;
            },
            getNextNumber(numbers) {
                if (numbers.length == 0) {
                    return 1;
                }

                return Math.max(...numbers) + 1;
            },
            async saveArticleCategory() {
                if (this.articleCategory.id == undefined) {
                    await this.$axios.post('/api/ArticleCategory/AddArticleCategory', {
                        name: this.articleCategory.name,
                        sort: this.articleCategory.sort,
                    });
                    this.articleCategoryDialogVisible = false;
                    this.loadArticleCategories();
                }
                else {
                    await this.$axios.post('/api/ArticleCategory/UpdateArticleCategory', {
                        id: this.articleCategory.id,
                        name: this.articleCategory.name,
                        sort: this.articleCategory.sort,
                    });
                    this.articleCategoryDialogVisible = false;
                    this.loadArticleCategories();
                }
            },
            async deleteArticleCategory(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/ArticleCategory/DeleteArticleCategory', { id: id });
                this.loadArticleCategories();
            },
        },
        created() {
            this.loadArticleCategories();
        },
    };
</script>